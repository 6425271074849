import { defineStore } from 'pinia'
import { Interactions } from '@/modules/requests/interactions';
import { ref, Ref } from 'vue';

export interface KickType {
    player_id: string,
    reason: string
}

interface FunctionParams {
    [key: string]: string;
}

export interface FunctionType {
    _id ?: string,
    name: string,
    ingame_func: string,
    params: FunctionParams
}

export interface FindPlayerType {
    player_id: string,
    player_name: string,
    server_id: string,
    private_server_id: string,
    game_id: string,
    place_id: string,
    link: string,
    image_url?: string
}

export const functionsList: Ref<FunctionType[]> = ref([]);

export const useInteractionsStore = defineStore('interactions', () => {

    async function kickPlayer(data: KickType) {
        await Interactions.kickPlayer(data)
    }

    async function findPlayer(id: string) {
        return await Interactions.findPlayer(id)
    }

    async function notifyServers(message: string) {
        return await Interactions.notifyServers(message)
    }

    async function createFunction(newFunction: FunctionType) {
        const { data } = await Interactions.createFunction(newFunction);
        await getFunctions();
        return data['id'];
    }

    async function getFunctions() {
        functionsList.value = await Interactions.getFunctions();
    }

    async function updateFunction(func: FunctionType) {
        await Interactions.updateFunction(func);
        await getFunctions();
    }

    async function deleteFunction(id: string) {
        await Interactions.deleteFunction(id);
        await getFunctions();
    }

    async function triggerFunction(func: FunctionType) {
        await Interactions.triggerFunction(func);
    }

    return { kickPlayer, findPlayer, notifyServers, createFunction, getFunctions, updateFunction, deleteFunction, triggerFunction }
})