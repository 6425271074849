import { Requester } from '../requester';
import { actualWorkspace } from '@/store/workspaces';
import { KickType, FunctionType } from '@/store/interactions';

export class Interactions {
    static async kickPlayer(data: KickType){
        const kickRequester = new Requester('/interactions/kick-player');
        await kickRequester.post({
            'workspace_id': actualWorkspace.value['_id'],
            ...data
        })
    }

    static async findPlayer(id: string){
        const findRequester = new Requester('/interactions/find-player');
        const temp = {
            'workspace_id': actualWorkspace.value['_id'],
            'player_id': id
        }
        const params = new URLSearchParams(temp).toString();
        return await findRequester.get(params)
    }

    static async notifyServers(message: string){
        const requester = new Requester('/interactions/notify-servers');
        await requester.post({
            'workspace_id': actualWorkspace.value['_id'],
            'message': message
        })
    }

    static async createFunction(func: FunctionType){
        const requester = new Requester('/functions');
        return await requester.post({
            'workspace_id': actualWorkspace.value['_id'],
            ...func
        })
    }

    static async getFunctions(): Promise<FunctionType[]> {
        const requester = new Requester('/functions');
        const temp = {
            'workspace_id': actualWorkspace.value['_id']
        }
        const params = new URLSearchParams(temp).toString();
        return await requester.get(params)
    }

    static async updateFunction(func: FunctionType){
        const requester = new Requester('/functions');
        await requester.patch({
            'workspace_id': actualWorkspace.value['_id'],
            ...func
        })
    }

    static async deleteFunction(id: string){
        const requester = new Requester('/functions');
        await requester.delete({
            'workspace_id': actualWorkspace.value['_id'],
            '_id': id
        })
    }

    static async triggerFunction(func: FunctionType){
        const requester = new Requester('/functions/run');
        await requester.post({
            'workspace_id': actualWorkspace.value['_id'],
            ...func
        })
    }
}